// component for repeatable center list items 
// The component will display the center name and estimated wait time within a card.
// The card will be clickable and will link to the center's page.


export default function CenterList(props) {
    return (
        <div className="center-list-card">
            <h2 className="center-name">{props.name}</h2>
            <div className="center-address">
                <p className="center-wait">Estimated Wait Time</p>
                <p className="center-wait-time">{props.wait}</p>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="center-list-icon" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.66671 3.22454L16.3877 11.9455L7.66671 20.6665" stroke="#231F20" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </div>
    );
}

