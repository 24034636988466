import React from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate } from "react-router-dom";

import "../App.css";

// Authentication not currently implemented.
// 'Login' button will navigate to AllCenterList page.

export default function Login(){

    const navigate = useNavigate();

    return (
        <div className="login-container">
            <div className="login">
                <div className="login-header">
                    <h4 className="login-greeting">Hi There!</h4>
                    <h1 className="login-subheader">Please login to check waiting times.</h1>
                </div>
                <div className="login-form-container">
                    <form className="login-form">
                        <h2 className="login-label">Username</h2>
                        <input className="login-input" type="text" name="username" required/>
                        <h2 className="login-label">Password</h2>
                        <input className="login-input" type="password" name="password" required/>
                    </form>
                </div>
                <div className="login-button-container">
                    <button className="login-button" type="submit" 
                    onClick={() => {
                        navigate("/allcenterlist");
                    }}
                    >Login</button>
                    <a href="/forgotpassword" className="forgot-password">
                        <h3 className="forgot-password">Forgot Password?</h3>
                    </a>
                </div>
            </div>
        </div>
    );
}