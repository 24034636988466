import React from "react";
import AllCenterList from "./pages/AllCenterList";
import Login from "./pages/Login";
import "./App.css";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";


export default function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login/>}/>
                <Route path="/allcenterlist" element={<AllCenterList/>}/>
            </Routes>
        </Router>
    );
}

