import { useEffect, useState } from "react";
import Dropdown from "react-dropdown";
import CenterList from "../components/CenterList";
import LoginStatus from "../components/LoginStatus";

import "../App.css";

export default function AllCenterList() {
    const [data, setData] = useState([]);
    const [user, setUser] = useState("Ryan S."); 

    const fetchData = () => {
        var myHeaders = new Headers();
        myHeaders.append("x-api-key", "Y4e60GRpGe9T92G1AKu9Yan66TZQdKmY96Owle0D");
        //myHeaders.append("Access-Control-Allow-Origin", "*");
        //myHeaders.append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
            mode: 'cors'
        };
        fetch("https://2dm2b5ohaa.execute-api.us-east-2.amazonaws.com/v1/WaitTimePerStore", requestOptions)
            .then((response) => response.json())
            .then((actualData) => {
                console.log("actual data")
                console.log(actualData);
                setData(actualData);
                //console.log(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    };
    useEffect(() => {
        fetchData();
        // append data with dummy data for testing
    }, []);
    function secondsToHms(d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
    
        var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        var combined = hDisplay + mDisplay + sDisplay
        var returnval = combined.replace(/,\s*$/, "");
        if (returnval == "") {
            returnval = "Less than 1 minute"
        }
        return returnval; 
    }
    function bucketTime(d) {
        d = Number(d);
        var m = Math.floor(d / 60);
        var display = "unknown"
        //console.log(m)
        if(m < 5){
            display = "Less than 5 minutes"
        }
        else if (m >= 5 && m <= 10)
        {
            display = "5 to 10 minutes"
        }
        else if (m > 10) {
            display = "More than 10 minutes"
        }
        return display
    }
    // return (
    //     <div className="App">
    //         <table>
    //             <tbody>
    //             <tr>
    //                 <th>Center Number</th>
    //                 <th>Wait Time</th>
    //                 <th>Last Update</th>
    //                 <th>Published Wait Time</th>
    //             </tr>
    //             {data.map((item, index) => (
    //                 <tr key={index}>
    //                     <td>{item.storeno}</td>
    //                     <td>{secondsToHms(item.wait)}</td>
    //                     <td>{item.time}</td>
    //                     <td>{bucketTime(item.wait)}</td>
    //                 </tr>
    //             ))}
    //             </tbody>
    //         </table>
    //     </div>
    // );

    return (
        <div className="App">
            <body>
                <header className="header">
                <div className="login-status-header">
                        <LoginStatus user={user}/>
                    </div>
                </header>
                <main className="main">
                        <div className="search-bar">
                            <input type="text" placeholder="Search for centers..." name="search" className="search"/>
                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" className="search-icon" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="27.8926" cy="20.313" r="16.5" transform="rotate(45 27.8926 20.313)" stroke="black" stroke-width="3"/>
                                <path d="M3.49653 43.7991C2.91074 44.3849 2.91075 45.3347 3.49653 45.9205C4.08232 46.5062 5.03207 46.5062 5.61785 45.9205L3.49653 43.7991ZM15.3153 31.9804L3.49653 43.7991L5.61785 45.9205L17.4366 34.1017L15.3153 31.9804Z" fill="black"/>
                            </svg>
                        </div>
                        <div className="center-list-header">
                            <h1 className="all-centers-header">All Centers</h1>
                            <div className="center-list-header-right">
                                <span className="sort-by-header">Sort by</span>
                                <div className="sort-by-dropdown-container">
                                    <Dropdown options={['Wait Time', 'Distance', 'Center Number']} placeholder="Wait Time" className="sort-by-dropdown" />
                                </div>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.775 7.66671L12.054 16.3877L3.33301 7.66671" stroke="#009CBD" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    <div className="center-list-cards">
                        {data.map((item, index) => (
                            <CenterList key={index} name={item.storeno} wait={bucketTime(item.wait)} bucket={bucketTime(item.wait)}/>
                        ))}
                    </div>
                </main>
                <footer>

                </footer>
            </body>
        </div>
    );

}


