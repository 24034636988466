import {useState} from 'react'

export default function LoginStatus(props) { 
    
    return (
        <div className="login-status">
            <h1 className="login-text">Logged in as </h1>
            <span className="user-text">{props.user}</span>
            <a href="/logout" className="logout-link">Logout</a>
        </div>
    );
}